/* eslint-disable no-param-reassign, class-methods-use-this */
/* global hbspt */
/**
 * Licent
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

const escKey = (event, callback) => {
  if (event.defaultPrevented) {
    return;
  }

  const key = event.key || event.keyCode;

  if (key === 'Escape' || key === 'Esc' || key === 27) {
    callback();
  }
};

/**
 * Modal Controller.
 */
export default class extends Controller {
  static targets = ['close'];

  escListener;

  formId = 0;

  /**
   * Mount.
   */
  connect() {
    this.formId = `form-${Math.random().toString(36).slice(2)}`;
    this.element.dataset.formId = this.formId;
  }

  /**
   * Show modal
   *
   * @param {String} formId
   * @param {String} portalId
   * @param {Function} onFormReady
   */
  show(formId = null, portalId = null, onFormReady = null) {
    this.element.classList.add('is-active');
    document.body.classList.add('no-scroll');
    this.closeTarget.focus();

    if (portalId && formId) {
      hbspt.forms.create({
        target: `[data-form-id="${this.formId}"] [data-modal-target="form"]`,
        region: 'eu1',
        portalId,
        formId,
        onFormReady: onFormReady || this.onFormReady.bind(this),
      });
    }

    this.escListener = document.addEventListener('keyup', (e) => escKey(e, this.close.bind(this)));
    this.element.dispatchEvent(new Event('modal.show'));
  }

  /**
   * Close modal
   */
  close() {
    this.element.classList.remove('is-active');
    document.body.classList.remove('no-scroll');
    document.removeEventListener('keyup', this.escListener);
    this.element.dispatchEvent(new Event('modal.hide'));
  }

  /**
   * On form ready.
   *
   * @param {Object} form
   */
  onFormReady(form) {
    const location = form.querySelector('input[name="pagelocation"]');
    if (location) {
      location.value = window.location.href;
    }
  }
}
