// Stimulus
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers';

// Turbo
import * as Turbo from '@hotwired/turbo';

// Assets
import '../assets/img/favicon/apple-touch-icon.png';
import '../assets/img/favicon/favicon-32x32.png';
import '../assets/img/favicon/favicon-16x16.png';
import '../assets/img/svg/icon-arrow.svg';
import '../assets/img/svg/icon-circle-arrow.svg';
import '../assets/img/svg/icon-chevron.svg';
import '../assets/img/svg/icon-dyslexia.svg';
import '../assets/img/svg/icon-facebook.svg';
import '../assets/img/svg/icon-instagram.svg';
import '../assets/img/svg/icon-linkedin.svg';
import '../assets/img/svg/icon-x.svg';
import '../assets/img/svg/icon-zoom.svg';
import '../assets/img/svg/icon-check-round.svg';
import '../assets/img/svg/icon-conversation.svg';
import '../assets/img/svg/icon-house.svg';
import '../assets/img/svg/icon-bulb.svg';
import '../assets/img/svg/icon-return.svg';
import '../assets/img/svg/icon-plus.svg';
import '../assets/img/svg/icon-play.svg';
import '../assets/img/svg/icon-clock.svg';
import '../assets/img/svg/icon-phone.svg';
import '../assets/img/svg/icon-upload.svg';
import '../assets/img/svg/icon-search.svg';
import '../assets/img/svg/icon-filter.svg';
import '../assets/img/svg/icon-whatsapp.svg';
import '../assets/img/svg/icon-download.svg';
import '../assets/img/svg/icon-pin.svg';
import '../assets/img/svg/icon-map-pin.svg';
import '../assets/img/svg/icon-map-pin-hover.svg';
import '../assets/img/svg/icon-establishment-pin.svg';
import '../assets/img/svg/icon-establishment-pin-hover.svg';
import '../assets/img/svg/icon-close-round.svg';
import '../assets/img/svg/icon-loader.svg';
import '../assets/img/svg/icon-external.svg';
import '../assets/img/svg/icon-mail.svg';
import '../assets/img/svg/checkmark.svg';
import '../assets/img/svg/logo.svg';
import '../assets/img/svg/shape-drop-fill.svg';
import '../assets/img/svg/shape-drop-mask.svg';
import '../assets/img/svg/shape-drop-mask-2.svg';
import '../assets/img/svg/shape-drop-stroke.svg';
import '../assets/img/svg/shape-drop-stroke-small.svg';
import '../assets/img/svg/shape-shield-fill.svg';
import '../assets/img/svg/shape-shield-stroke.svg';
import '../assets/img/svg/shape-shield-stroke-small.svg';

// Logo's offices
import '../assets/img/logo/asbroek.png';
import '../assets/img/logo/asbroek.svg';
import '../assets/img/logo/baartman.png';
import '../assets/img/logo/baartman.svg';
import '../assets/img/logo/baksteenpul.png';
import '../assets/img/logo/baksteenpul.svg';
import '../assets/img/logo/berendsslump.png';
import '../assets/img/logo/berendsslump.svg';
import '../assets/img/logo/deterink.png';
import '../assets/img/logo/deterink.svg';
import '../assets/img/logo/dommerholttenbrinke.png';
import '../assets/img/logo/dommerholttenbrinke.svg';
import '../assets/img/logo/koopman.png';
import '../assets/img/logo/koopman.svg';
import '../assets/img/logo/licent.png';
import '../assets/img/logo/licent.svg';
import '../assets/img/logo/licent-groep.svg';

// Start Stimulus
const application = Application.start();
const controllers = import.meta.glob('./**/*_controller.js', { eager: true });
registerControllers(application, controllers);

// Start Turbo
Turbo.start();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR'); // eslint-disable-line no-console
  });
}
