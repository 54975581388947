/* eslint-disable no-param-reassign, class-methods-use-this */
/* global hbspt */
/**
 * Licent
 *
 * @author Robuust
 * @author Bob Olde Hampsink <bob@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
/**
 * Hubspot Controller.
 */
export default class extends Controller {
  static values = {
    portalId: String,
    formId: String,
  };

  /**
   * Mount.
   */
  connect() {
    hbspt.forms.create({
      target: this.scope.controllerSelector,
      region: 'eu1',
      portalId: this.portalIdValue,
      formId: this.formIdValue,
      onFormReady: this.onFormReady.bind(this),
    });
  }

  /**
   * On form ready.
   *
   * @param {Object} form
   */
  onFormReady(form) {
    form.querySelector('input[name="pagelocation"]').value = window.location.href;
  }
}
