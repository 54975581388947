/* eslint-disable no-param-reassign */
/**
 * Licent hypotheken hub
 *
 * @author Robuust
 * @author Joost Waaijer <joost@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';

/**
 * Controller for mortgage calculation.
 */
export default class extends Controller {
  static targets = [
    'mortgageInterest', 'form', 'result', 'mortgageText', 'monthlyCostText',
    'personOneInput', 'personTwo', 'personTwoInput', 'emailForm', 'personOneInputIncome',
    'personTwoInputIncome', 'stepOne', 'monthlyIncomeLabel',
  ];

  static values = {
    buttonColor: String,
    incomeType: { type: String, default: 'month' },
  };

  currencyFormat = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' });

  monthlyCosts = 0;

  mortgage = 0;

  formId = 0;

  holidayAllowance = 1.08;

  modalController = null;

  connect() {
    this.formId = `form-${Math.random().toString(36).slice(2)}`;
    this.element.dataset.formId = this.formId;

    const modal = document.getElementById('modal-hubspot');
    this.modalController = this.application.getControllerForElementAndIdentifier(modal, 'modal');
  }

  /**
   * Calculate monthly costs
   *
   * @param {Event} e
   */
  submitForm(e) {
    if (!this.validateForm(this.formTarget)) {
      return;
    }
    e.preventDefault();

    if (this.hasEmailFormTarget) {
      e.target.classList.add('bg-cyan');
    }

    const monthlyInterest = this.mortgageInterestTarget.value / 12;
    let amount = +this.personOneInputTarget.value;
    if (!this.personTwoTarget.classList.contains('hidden')) {
      amount += +this.personTwoInputTarget.value;
    }

    this.mortgage = ((amount * 20.5) / 12) / monthlyInterest;
    this.resultTargets.forEach((result) => result.classList.remove('hidden'));

    this.stepOneTarget.classList.add('hidden');

    this.mortgageTextTarget.innerHTML = this.currencyFormat.format(this.mortgage);
    this.calculateMonthlyCost(this.mortgage, monthlyInterest);
  }

  /**
   * Calculate monthly costs
   *
   * @param {Number} amount
   * @param {Number} monthlyInterest
   */
  calculateMonthlyCost(amount, monthlyInterest) {
    const interest = monthlyInterest / 100;
    this.monthlyCosts = (interest / (1 - ((1 + interest) ** -360))) * amount;
    this.monthlyCostTextTarget.innerHTML = this.currencyFormat.format(this.monthlyCosts);
  }

  /**
   * Select amount of persons
   *
   * @param {Event} e
   */
  selectPersons(e) {
    const value = +e.target.value;
    if (value === 1) {
      this.personTwoTarget.classList.add('hidden');
      this.personTwoInputTarget.value = null;
      this.personTwoInputTarget.required = false;
    } else {
      this.personTwoTarget.classList.remove('hidden');
      this.personTwoInputTarget.required = true;
    }
  }

  /**
   * Change incomeType
   *
   * @param {Event} e
   */
  changeIncomeType(e) {
    this.incomeTypeValue = e.target.value;

    if (this.hasMonthlyIncomeLabelTarget) {
      if (this.incomeTypeValue === 'month') {
        this.monthlyIncomeLabelTarget.innerHTML = 'Wat is je bruto inkomen per maand?';
      }

      if (this.incomeTypeValue === 'week') {
        this.monthlyIncomeLabelTarget.innerHTML = 'Wat is je bruto inkomen per 4 weken?';
      }
    }
  }

  /**
   * Validate form
   *
   * @return {Boolean}
   */
  validateForm() {
    let isValid = true;

    const requiredFieldSelectors = 'input:required';
    const requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        isValid = false;
      }
    });

    return isValid;
  }

  /**
   * Show and populate email form
   *
   * @param {Event} e
   */
  showEmailForm(e) {
    if (this.modalController) {
      this.modalController.show(e.params.form, e.params.portal, this.onEmailFormReady.bind(this));
    } else {
      throw Error('Modal controller not found');
    }
  }

  /**
   * On email form ready.
   *
   * @param {Object} form
   */
  onEmailFormReady(form) {
    form.querySelector('input[name="berekende_maximale_hypotheek"]').value = this.currencyFormat.format(this.mortgage);
    form.querySelector('input[name="ingevoerd_jaarinkomen"]').value = this.currencyFormat.format(+this.personOneInputTarget.value);
    form.querySelector('input[name="ingevoerd_jaarinkomen_partner"]').value = this.currencyFormat.format(+this.personTwoInputTarget.value);
    form.querySelector('input[name="pagelocation"]').value = window.location.href;
    form.querySelector('input.hs-button').style.backgroundColor = this.buttonColorValue;
    form.querySelector('input.hs-button').style.borderColor = this.buttonColorValue;

    const monthlyCosts = form.querySelector('input[name="berekende_bruto_maandlasten"]');
    if (monthlyCosts) {
      monthlyCosts.value = this.currencyFormat.format(this.monthlyCosts);
    }

    const netMontlyCosts = form.querySelector('input[name="berekende_netto_maandlasten"]');
    if (netMontlyCosts) {
      netMontlyCosts.value = this.currencyFormat.format(this.monthlyCosts * 0.63);
    }
  }

  /**
   * Calculate yearly income
   *
   * @param {Event} event
   */
  calculateYearlyIncome(event) {
    const incomeTarget = this.targets.find(`${event.params.target}Income`);
    if (this.incomeTypeValue === 'month') {
      this.targets.find(event.params.target).value = (
        +incomeTarget.value * 12 * this.holidayAllowance
      ).toFixed(2);
    }

    if (this.incomeTypeValue === 'week') {
      this.targets.find(event.params.target).value = (
        +incomeTarget.value * 13 * this.holidayAllowance
      ).toFixed(2);
    }

    this.closeModal(event.params.id);
  }

  /**
   * Close modal
   *
   * @param {String} id
   */
  closeModal(id) {
    const modal = document.getElementById(`modal-${id}`);
    const controller = this.application.getControllerForElementAndIdentifier(modal, 'modal');

    if (controller) {
      controller.close();
    } else {
      throw Error('Modal controller not found');
    }
  }
}
